.provider-hours-worked {
  padding: 10px 20px;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }

    .header-controls {
      display: flex;
      align-items: center;
      gap: 15px;

      .save-changes-btn {
        background-color: #81e6d9;
        color: #fff;
        border: none;
        border-radius: 20px;
        padding: 8px 16px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: background-color 0.3s;

        &:hover {
          background-color: #4fd1c5;
        }
      }
    }
  }
}
.info-box {
  background-color: #d9edf7;
  border-radius: 3px;
  padding: 5px 16px;
  font-size: 13px;
  font-weight: 600;
  margin: 10px 15px;
}
.save-changes-btn-down {
  background-color: #81e6d9;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s;

  margin: 15px;

  &:hover {
    background-color: #4fd1c5;
  }
}
