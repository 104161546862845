.ne__widget__footer {
  border-top: 1px solid #edeeee;
  width: 100vw;
  span {
    color: $light-color;
    text-decoration: none;
    font-size: 12px;
    &:hover {
      color: #51bce9 !important;
    }
  }
}

.footer-dot {
  font-size: 1.3rem;
  color: $light-color;
}
