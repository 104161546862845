@import '~bootstrap/scss/bootstrap';
@import './colors';
@import './utils';

@import './auth/login';
// pages
@import './pages/appointment-book.scss';

// dashboard pages
@import './dashboard/dashboard';
@import './dashboard/dashboard-home';
@import './dashboard/phoneIQ';
@import './dashboard/enterPrice';
@import './dashboard//doctor';

//component
@import './component/appointmentDetails';
@import './component/appointment-form';
@import './component/appointment-list';
@import './component/appointment-date';
@import './component/date-scroll';
@import './component/insurance';
@import './component/location';
@import './component/map-route';
@import './component/schedule';
@import './component/time-slot';
@import './component//clinics.scss';
@import './component/checkEligibility';
@import './component/scheduleCheckbox';
@import './component/provider-hours-worked';
@import './component/markeing_roi';
@import './component/datescroll';
@import './component//filterEnterPrise';
@import './component/admin-report';
// footer
@import './footer/footer';

//ag-Grid
@import './agGrid/ag-grid';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #161616;
  // overflow-y: hidden;
}
ul {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.ne_widget-appt-type {
  margin: 0 auto;
  width: calc(100% / 12 * 8);
}
.ne_widget-appt-form {
  margin: 0 auto;
  width: calc(100% / 12 * 7);
}

p {
  margin: 0;
  padding: 0;
}

span {
  font-size: 13.33px;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

abbr[title] {
  text-decoration: none;
}
.primary_color {
  color: red;
}
.ne_dropdown_calendar {
  .react-calendar {
    border: 1px solid #dddfdf !important;
    border-radius: 10px;
    // position: relative;
    // left: 100px;

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      background-color: #00cbe6 !important;
      color: #fff;
    }
    .react-calendar__tile {
      font-size: 13.33px !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ne_widget-appt-form {
    margin: 0 auto;
    width: 90%;
  }
  .ne_widget-appt-type {
    margin: 0 auto;
    width: 90%;
  }
}
.book_app {
  font-weight: bold;
  border: none !important;
  &:hover {
    background: #ff596f !important;
  }
  &:focus {
    background: #ff596f !important;
  }
}
// form
label {
  text-transform: capitalize !important;
  font-weight: 500;
}
input {
  // border-top: none !important;
  // border-left: none !important;
  // border-right: none !important;
  // border: none none 1px none gray !important;
  &::placeholder {
    font-size: 0.9rem;
  }
}
.cursors {
  cursor: pointer;
}

.form_error {
  background: #ffe4e6;
}
.br_d {
  border: 1px solid red;
}
.br_c {
  border: 1px dashed blue;
}

.scheduler_date {
  position: absolute;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
// .sidebar {
//   overflow: hidden;
//   transition: 0.4s all ease-in-out;
//   &_items {
//     padding: 1rem;
//     margin: 0;
//     li {
//       padding: 0;
//       border: 1px solid gray;
//       a {
//         width: 100%;
//         display: flex;
//       }
//     }
//   }
// }
// .nav_bar {
//   cursor: pointer;
//   font-size: 1.8rem;
// }
// .sidebar_width {
//   position: absolute;
//   transition: 0.4s all ease-in-out;
//   left: -100%;
//   width: 0;
// }

// @media only screen and (max-width: 600px) {
//   .sidebar_mobile_view {
//     position: absolute;
//     transition: 0.4s all ease-in-out;
//     left: 0%;
//     height: 100%;
//     width: 200px !important;

//     z-index: 10;
//     background: #ffff;
//   }
//   .sidebar_mobile_view_res {
//     position: absolute;
//     transition: 0.4s all ease-in-out;
//     left: -100%;
//     width: 0;
//   }
// }

// .sidebar_normal {
//   width: 15%;
// }
// .right_side {
//   width: 85%;
// }
// .right_side_toggle {
//   width: 100%;
// }
