$clock-color: rgb(74, 175, 214);
$cal-color: rgb(238, 128, 85);
$dollar-color: rgb(89, 193, 164);
$users-color: rgb(35, 158, 216);
$user-text: rgb(221, 119, 119);
$text-color: rgb(138, 144, 162);
$bar-color: rgb(69, 210, 185);
$timer-color:rgb(187, 189, 48);

.text_color {
  color: $text-color;
  font-weight: 500;

}
.incoming_ph_icon{
  color:$dollar-color;
}
.outgoing_ph_icon{
  color:$clock-color;
}
.timer_icon{
  color:$timer-color;
}
.font_bold {
  font-weight: bold;
}
.details_value {
  color: $user-text;
}
.dashboard_section {
  p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 0.8rem;
  }
  .dash_icon {
    font-size: 1.5rem;
  }
  .clock_icon {
    color: $clock-color;
  }
  .cal_icon {
    color: $cal-color;
  }
  .dollar_icon {
    color: $dollar-color;
  }
  .user_icon {
    color: $users-color;
  }
}
.chart_g {
  font-size: 2rem !important;
  color: $bar-color;
}
.progress-bar {
  background-color: $bar-color;
}
.dropdown {
  &:hover {
    .dropdown_none {
      color: $text-color !important;
      // display: block !important;
    }
  }
}
.dropdown_none {
  display: block;
}

.filter_dashboard {
  .filter_dashboard_left_side {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &_left_side {
    .ant-select-selector {
      display: flex;
      border: none !important;
      align-items: center;
      width: 140px !important ;
      // height: 100px;
      border-radius: 20px !important;
    }
    .ant-picker {
      padding: 0.5rem 0.8rem !important;
      border-radius: 20px !important;
      border: none !important;
      // width: 150px !important ;
    }
  }
}
// .dropdown-menu {
//   width: 100%;
//   min-width: 0 !important;
// }
.ant-dropdown-menu {
  border-radius: 20px !important;
  padding: 0.2rem 0.5rem !important;
  li {
    border-radius: 0;
  }
}
.profile_menu {
  width: 15rem !important;
}
.border_radius {
  border-radius: 12px;
}
.big_cards {
  &_card {
    // height: 600px;
    border-radius: 12px;
    .progress_card {
      &:hover {
        .hover_dots {
          color: black !important;
        }
      }
    }
    .progress_heading {
      font-size: 1rem;
      font-weight: 600 !important;
    }
  }
}
.amt_data {
  color: black;
  font-weight: bold;
}
.progress_heading {
  font-size: 1rem;
  font-weight: 600 !important;
}
.col-hover-effect {
  position: relative; 

  .menu-icon {
    opacity: 0; 
    transition: opacity 0.3s ease; 
  }

  &:hover .menu-icon {
    opacity: 1; 
  }
}

.hoverable-row {
  position: relative;

  .dots-icon {
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .dots-icon {
    opacity: 1; 
  }

  .align-items-center {
    display: flex;
    align-items: center;
  }

  .text-end {
    text-align: right;
  }

  .text-start {
    text-align: left;
  }
}
.circle-hover {
  opacity: 0;
  transition: opacity 0.3s;
}

strong:hover .circle-hover {
  opacity: 1; 
}

