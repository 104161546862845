.ne_widget_header {
   border-top: 1px solid #edeeee;

   width: 100%;
   &:hover {
     cursor: pointer;
     background-color: #edeeee;
   }

   .ne_widget_sidebar_thumb {
     position: relative;
     width: 40px;
     .ne_icon_star-of-life {
       .ne_widget_index {
         position: absolute;
         top: 25%;
         left: 50%;
         transform: translate(-50%, -50%);
         color: #fff;
       }
     }
   }

   .ne_widget_icon {
     color: #00cbe6;
     width: 35%;
   }
 }

 .ne_widget {
   .ne_widget_eyebrow {
     color: #858a8e;
     text-transform: uppercase;
     font-weight: 500;
     letter-spacing: 0.1em;
     margin-bottom: 0.5em;
     font-size: 9.26px;
   }
   .ne_widget_name {
     font-size: 1rem;
   }

   .ne_link_phone {
     font-size: 13.33px;
     color: #00cbe6;
     text-decoration: none;
   }
 }