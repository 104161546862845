.auth_container {
  height: 100vh;
}
.lastLoggedInUser {
  h1 {
    font-weight: bold;
    color: black;
    .login_mail {
      border-radius: 1.5rem;
      padding: 0.25rem 0.5rem;
      display: block;
      width: fit-content;
      cursor: pointer;
      color: #29ace3;
      font-size: 1.9rem !important;
      font-weight: normal;
      &:hover {
        background-color: rgb(209, 243, 251);
        transition: all 1.5s cubic-bezier(0.32, 0.01, 0, 1); /* increased the duration to 1 second */
      }
      .arrow_right {
        font-size: 1.25rem;
        margin-left: 1.25rem;
      }
    }
  }
}
