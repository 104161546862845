@import "../../styles/colors";

.category_list {
  background-color: $light-bg-color;
  cursor: pointer;
  .list-title {
    color: $base-color;
    font-size: 13.33px;
    font-weight: 500;
  }
  &:hover {
    background-color: #e0e2e2;
  }
}

.category_list_active {
  background-color: $active-bg-color;

  .list_active_img {
    width: 20px;
  }

  cursor: pointer;
  .list-title {
    color: $color-white;
    font-size: 13.33px;
    font-weight: 500;
  }
}

li {
  list-style: none;
  padding: 12px 20px;
  border-radius: 0.25rem;
}
