.ag-cell-wrap-text {
  word-break: normal !important;
}
// .ag_grid_caps {
//   display: flex;
//   align-items: center;
//   height: 100%;
// }
.ag-cell {
  font-size: medium !important;
}
.is_active {
  padding: 0.3rem 1rem;
  border-radius: 6px;
  font-size: 0.8rem;
  background-color: #dcfce7;
  color: #22c55e;
  font-weight: bold;
}
.in_active {
  padding: 0.3rem 0.7rem;
  border-radius: 6px;
  background-color: #ffe4e6;
  color: #e11d48;
  font-weight: bold;
  font-size: 0.8rem;
}
.table_head {
  border: none !important;
}
tbody {
  border-top: none !important;
}
// .avail_days {
//   // display: flex;
//   display: block;
//   padding: 0 !important;
//   margin: 0 !important;
// &_day {
//   display: inline-block;
//   padding: 0.3rem 0.7rem;
//   border-radius: 6px;
//   background-color: #ffe4e6;
//   color: #e11d48;
//   font-weight: bold;
//   font-size: 0.8rem;
// }
// }
.avi_days {
  margin: 0.2rem;
  display: inline-block;
  padding: 0rem 0.3rem;
  border-radius: 6px;
  font-size: 0.8rem;
  background-color: #edeef3;
  color: #3730a3;
  // font-weight: bold;
}
.status {
  // display: inline-block;
  &_open {
    padding: 0.3rem 2rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #edeef3;
    color: #d946ef;
  }
  &_complete {
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #edeef3;
    color: #6edd6c;
  }
  &_scheduled {
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #edeef3;
    color: #4f46e5;
  }
  &_broken {
    padding: 0.3rem 1rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #f7e3e6;
    color: #d95050;
  }
}
.edit_btn {
  color: $active-bg-color;
}
.head_td {
  color: #00b7cf;
}

.active_btn {
  cursor: not-allowed !important;
  background-color: #c3e9ee;
  color: #ffff;
  border: none;
  &:focus {
    box-shadow: none !important;
    background: #c3e9ee;
    //  background: #BFDBFE;
    color: #ffff;
  }
  &:hover {
    background: #c3e9ee;
    color: #ffff;
  }
  &_active {
    cursor: pointer !important ;
    color: #fff;
    background: #50def0;
    &:focus {
      color: #fff;
      background: #50def0;
    }
    &:hover {
      color: #fff;
      background: #50def0;
    }
  }
}
.ag-root-wrapper {
  border-radius: 4px;
}
.ag-header {
  background-color: #fff !important;
}
.ag-row-odd {
  background: #f3f4f6 !important;
}

.ag-cell-value {
  color: #4b5563 !important;
}

.ag_grid_caps {
  text-transform: capitalize !important;
}
.ag-header-cell-label {
  width: 100%;
}
.actions_ag_grid {
  &_btn {
    border: none;
    color: #f4f5f7;
    padding: 0.2rem 0.8rem;
    background: #59caff;
    cursor: pointer;
    &:focus {
      outline: none;
      background: #59caff;
    }
    &:hover {
      background: #59caff;
    }
    span {
      font-weight: bold;
      margin: 0 4px;
    }
  }
  img {
    width: 30px;
  }
}
.btn_disable {
  background: #b7ced8;
}

.actions_ag {
  &_btn {
    border: none;
    color: #8b99a0 !important;
    padding: 0.2rem 0.8rem;
    background: #adcbd8;
    cursor: pointer;
    &:focus {
      outline: none;
      background: #b7ced8;
    }
    &:hover {
      background: #accbd8;
    }
    span {
      font-weight: bold;
      margin: 0 4px;
    }
  }
  img {
    width: 30px;
  }
}
