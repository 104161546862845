// @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap');

// @import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

// @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Oxygen:wght@300;400;700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Oxygen:wght@300;400;700&family=Roboto:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Oxygen:wght@300;400;700&family=Roboto:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.ne_arrow {
  font-size: 1rem;

  color: #141617;
  &:hover {
    cursor: pointer;
  }
}
.arr_right {
  font-size: 1rem;

  color: #141617;
  &:hover {
    cursor: pointer;
  }
}
.table th,
.table td {
  padding-top: 0px;
  padding-bottom: 7px;
}
.pcoded-content {
  padding-top: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
}
// ============== btn scroll =============

.date_scroll_container {
  position: relative;

  .date_scroll_calendar {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 200;
  }
}

.date_scroll_btn {
  font-size: 100px;
  font-weight: 300;
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
  height: 30px;
  border: none;
  color: #141617;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;

  // .date_scroll_btn_invisible {
  //   font-size: 12rem !important;
  //   font-weight: 350;

  // font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';
  // }
  // .date_scroll_container {
  //   font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';
  //   font-family: '';
  // }

  .date_scroll_btn_visible {
    height: 100%;
    width: 100%;
    transition: all 0.2s;
  }

  .date_scroll_btn_invisible {
    font-size: 0.93rem !important;
    font-weight: 450 !important;
    position: absolute;
    color: #fff;
    height: 100%;
    width: 100%;
    left: 0;
    top: -100%;
    transition: all 0.2s;
  }

  &:hover {
    background-color: #48c0f3 !important;
  }
  &:hover .date_scroll_btn_visible {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  &:hover .date_scroll_btn_invisible {
    top: 0;
  }
  &:focus {
    outline: none;
    -webkit-animation: pulsate 2s infinite;
    animation: pulsate 2s infinite;
  }
}
.react-calendar {
  border: 1px solid #dddfdf !important;
  border-radius: 10px;

  .react-calendar__tile--active:enabled,
  .react-calendar__tile--hasActive {
    background-color: #48c0f3 !important;
    color: #fff;
  }
  .react-calendar__tile {
    font-size: 13.33px !important;
  }
}
.react-calendar__tile--now {
  background-color: #cdcdcd !important;
}
// .react-calendar__tile--active {
//   border: 1px solid !important;
//   background: #48c0f3 !important;
// }

// .react-calendar__tile--active:enabled:hover {
//   background: #48c0f3 !important;
// }
