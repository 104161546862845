.sidebar {
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 300px;
  z-index: 1054 !important;
  // max-width: 16%;
  background-color: #ffff;
  transition: 0.3s all ease-in-out;
  &_logo {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 180px !important;
    img {
      width: 100%;
    }
  }
  &_menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &_menu {
    position: relative;
    height: 100%;
    overflow: hidden;
    // overflow-y: hidden;
    overflow-y: scroll;
    margin-bottom: 2rem;
    &::-webkit-scrollbar {
      display: none;
    }

    &_item {
      height: 100%;
      height: 100% !important;
      cursor: pointer;
      text-align: left;
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: 400;
      color: #3d5170;
      padding: 0.39rem 2rem;
      .menu_item_icon {
        font-size: 1rem;
        color: #3d5170 !important;
      }
      &:hover {
        color: #2f5cfd;
        // border-left:inset 4px solid #2f5cfd;
        // box-shadow: inset 0.1875rem 0 0 #007bff;
      }
      &.active {
        color: #2f5cfd;
        box-shadow: inset 0.1875rem 0 0 #007bff;
        // background: #ff596f;
        height: 100%;
        background: #fbfbfb;
        // border-radius: 12px;
      }
    }
  }
}
.dropdown_s {
  overflow: hidden;
  transition: 0.3s all ease-in-out;
  height: 0px;
}
.dropdown_show {
  transition: 0.3s all ease-in-out;
  height: max-content;
}

.FromClass {
  z-index: 99 !important;
  width: 100%;
  border-radius: 10px;
  border-color: rgb(106, 9, 136) !important;
}
.ant-select-dropdown {
  z-index: 2000 !important;
}
.view {
  max-height: 100%;
  height: 100vh !important;
  overflow-y: scroll;
  background: #f4f5f7;
  width: 100%;
  max-width: 100%;

  &_navbar {
    position: sticky;
    z-index: 1050 !important;
    background: #fff;
    width: 100%;
    // box-shadow: 2px 6px rgb(226, 226, 226);
    .bar_btn {
      font-size: 1.8rem;
      color: rgb(99, 94, 94);
      cursor: pointer;
      margin-right: 2rem;
    }
    .navbar_container {
      display: flex;
      // border: 1px solid red;
      // max-width: 1600px !important;
      justify-content: space-between;
    }
    .search_bars {
      display: flex;
      justify-content: space-between;
      width: 80%;
      align-items: center;
      .nav_toggle {
        display: flex;
        align-items: center;
      }
      .server {
        font-size: 1.6rem;
        font-weight: bold;
        color: rgb(248, 113, 113);
        text-transform: uppercase;
      }
    }
    .dropdown-basic {
      // text-transform: capitalize;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
}
.sidebar_active {
  opacity: 0;
  transition: 0.3s all ease-in-out;
  width: 0;
  overflow: hidden;
  visibility: hidden;
}
.sidebar_not {
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.mack_models {
  .modal-dialog {
    max-width: 1124px !important;
    .modal-content {
      max-width: 100% !important;
    }
  }
}

.url_show {
  text-transform: capitalize;
  font-weight: bold;
}
.csv_download {
  font-size: 1.2rem;
  margin-right: 1rem;
  color: rgb(99, 143, 226);
}
.export {
  position: absolute;
  right: 5px;
  top: 2rem;
}

.date_flitter {
  span {
    color: rgb(0, 0, 0);
    font-weight: bold;
    margin-right: 10px;
  }
}

@media screen and (max-width: 492px) {
  .exportBtn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .date_flitter {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .filter_dashboard_right_side {
    margin-top: 0.5rem;
  }
  // #startDate,
  // #endDate {
  //   width: 120px;
  // }
  // .filter_dashboard {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  // }
}

@media screen and (max-width: 1000px) {
  .view_navbar .search_bars {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: flex-start;
  }
  .search_bars {
    display: flex;
    flex-direction: column;
  }
}

.eye_view_provider {
  font-size: 1.4rem;
}

label {
  color: gray;
}
input {
  color: gray;
  background: #ffffff;
  // border: 1px solid gray;
  &:focus {
    border: 1px solid #f4f5f7 !important;
    // outline: 1px !important;
  }
}
.resetBtn {
  padding: 0.3rem 0.8rem;
  background-color: #ff596f;
  color: #fff;
}
.exportBtn {
  // background-color: #ff596f;
  background: #007bff !important;
  color: #fff;
  border: none;
  &:focus {
    outline: none;
    // background: #ff596f;
  }
}

// clinics
// .disable_class {
//   color: gray;
// }
// users
.newModels {
  .modal-dialog {
    // margin: auto;
    max-width: 400px !important;
  }
}
.modal-backdrop {
  z-index: 1055;
}

@media only screen and (max-width: 1135px) {
  .sidebar {
    position: absolute !important;
    left: -100%;
    background-color: #fff;
    z-index: 99;
    &.sidebar_active {
      // display: block !important;
      opacity: 1;
      overflow: hidden;
      visibility: visible;
      left: 0;
      height: 100%;
      // height: 100vh !important;
      width: 320px !important;
      background-color: #fff;
      transition: 0.3s all ease-in-out;
    }
  }
  .sidebar_warper {
    background: rgba(0, 0, 0, 0.151);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1052;
    &.sidebar_warper_not {
      display: block;
    }
  }
  .view {
    &_navbar {
      display: block;
      font-size: 1;
    }
  }
}

.lowercase_email {
  text-transform: lowercase;
}
