.primary-mg-hoz {
  margin: 0 16px;
}
.primary-mg-ver {
  margin: 16px 0;
}
.secondary-mg-hoz {
  margin: 0 18px;
}
.secondary-mg-ver {
  margin: 18px 0;
}
.lg-mg-hoz {
  margin: 0 20px;
}
.lg-mg-ver {
  margin: 20px 0;
}
