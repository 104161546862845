.filter_doctor {
  display: flex;
  align-items: center;

  &_title {
    font-weight: bold;
    color: #0c4e99;
    margin: 0 !important;
  }

  &_btnContainer {
    margin-left: 8px;
    display: flex;
    align-items: center;

    &_left,
    &_middle,
    &_right {
      cursor: pointer;
      padding: 6px 12px;
      border: 1px solid gray;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s, color 0.3s;
    }

    &_left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &_middle {
      border-left: none;
      border-right: none;
    }

    &_right {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .active {
      color: 0c4e99;
      background-color: rgb(179, 174, 174);
      border: none !important;
    }
  }
}
